import { useState } from 'react';

import { API } from 'aws-amplify';

import './Chat.css';
import ChatMessage from './ChatMessage';
import { Button } from "react-bootstrap";

const Chat = () => {
    const initialData = {
        message: '',
        token: 'token3',
        thread_uuid: 'new',
        voice: 'male_1',
        model: 'gpt-3.5-turbo',
        skip_audio: 'yes'
    };
    const [data, setData] = useState(initialData);
    const [loading, setLoading] = useState(false);
    const [threads, setThreads] = useState([]);
    const [messages, setMessages] = useState([]);

    const handleChange = (event) => {
        const target = event.target;
        const name = target.name;

        let value = target.value;
        
        if ( target.type === "checkbox" ) {
            value = target.checked
        }
       
        setData({
            ...data,
            [name]: value
        });
    };

    const sendQuestion = async() => {
        if (data.message.trim().length === 0) {
            return;
        }
        console.log('data é', data);
        setLoading(true);
        try {
            let response = null;

            const body = {
                question: data.message,
                voice: data.voice
            }

            if (data.thread_uuid === 'new') {
                response = await API.post('dimo', `/chat/threads/initialize`, {
                    headers: {
                        'X-USER-TOKEN': data.token
                    },
                    body
                });
            } else {
                response = await API.post('dimo', `/chat/threads/${data.thread_uuid}/messages`, {
                    headers: {
                        'X-USER-TOKEN': data.token
                    },
                    body
                });
            }

            if (response.success) {

                let thread_uuid = data.thread_uuid
                if (thread_uuid === 'new') {
                    thread_uuid = response.data.question.dimo_thread_uuid;
                    setThreads([
                        ...threads,
                        response.data.thread
                    ]);
                    setData({
                        ...data,
                        thread_uuid
                    });
                }

                setMessages((messages) => [
                    ...messages,
                    response.data.question,
                    response.data.answer
                ]);
                response = await API.get('dimo', `/chat/threads/${thread_uuid}/messages/${response.data.answer.uuid}/generate?skip_audio=${data.skip_audio}&model=${data.model}`, {
                    headers: {
                        'X-USER-TOKEN': data.token
                    }
                });

                if (response.success) {
                    loadMessages(response.data.question.dimo_thread_uuid);
                } else {
                    console.log(response);
                }
                
            } else {
                alert('ocorreu um erro enviando mensagem');
                console.log(response);
            }
        } catch (e) {
            alert('ocorreu um erro enviando mensagem');
            console.log(e);
        } finally {
            setLoading(false);
        }
    };

    const loadChats = async () => {
        setLoading(true);
        setThreads([]);
        try {
            const response = await API.get('dimo', '/chat/threads', {
                headers: {
                    'X-USER-TOKEN': data.token
                }
                // body: {}
            });
            if (response.success) {
                setThreads(response.data.threads);
                if (response.data.threads.length > 0) {
                    setData(
                        {
                            ...data,
                            thread_uuid: response.data.threads[0].uuid
                        }
                    )
                    loadMessages(response.data.threads[0].uuid);
                }
            } else {
                alert('ocorreu um erro carregando os chats');
                console.log(response);
            }
        } catch (e) {
            alert('ocorreu um erro carregando os chats');
            console.log(e);
        } finally {
            setLoading(false);
        }
    };

    const handleSelectThread = (event) => {
        const value = event.target.value;

        setData({
            ...data,
            thread_uuid: value
        });

        loadMessages(value);
    };

    const loadMessages = async (thread_uuid) => {
        setLoading(true);
        setMessages([]);

        if (thread_uuid === 'new') {
            setLoading(false);
            return;
        }

        try {
            const response = await API.get('dimo', `/chat/threads/${thread_uuid}/messages?order=ASC`, {
                headers: {
                    'X-USER-TOKEN': data.token
                }
                // body: {}
            });
            if (response.success) {
                setMessages(response.data.messages);
            } else {
                alert('ocorreu um erro carregando o chat');
                console.log(response);
            }
        } catch (e) {
            alert('ocorreu um erro carregando o chat');
            console.log(e);
        } finally {
            setLoading(false);
        }
    };
    
    return (
        <div id="chat-container">
            <div id="chat-title">
                Token
                <br />
                <input
                    id="token"
                    name="token"
                    className="form-control"
                    placeholder="user token..."
                    type="text"
                    onChange={handleChange}
                    value={data.token}
                    rows={4}
                    cols={40}
                    disabled={loading}
                />
                <Button
                    onClick={loadChats}
                    disabled={loading}
                >
                    Carregar
                </Button>
                <br />
                Voz:
                <select
                    id="voice"
                    name="voice"
                    className="form-control"
                    onChange={handleChange}
                    value={data.voice}
                    disabled={loading}
                >
                    <option value="male_1">Masculina</option>
                    <option value="female_1">Feminina</option>
                </select>
                Gerar audio para cada pergunta:
                <select
                    id="skip_audio"
                    name="skip_audio"
                    className="form-control"
                    onChange={handleChange}
                    value={data.skip_audio}
                    disabled={loading}
                >
                    <option value="yes">Não</option>
                    <option value="no">Sim</option>
                </select>
                Modelo:
                <select
                    id="model"
                    name="model"
                    className="form-control"
                    onChange={handleChange}
                    value={data.model}
                    disabled={loading}
                >
                    <option value="gpt-3.5-turbo">gpt-3.5-turbo</option>
                    <option value="gpt-4">gpt-4</option>
                </select>
                <br /><br />
                Chat: 
                <br />
                <select
                    id="thread_uuid"
                    name="thread_uuid"
                    className="form-control"
                    onChange={handleSelectThread}
                    value={data.thread_uuid}
                    disabled={loading}
                >
                    {threads.map((thread, idx) => <option key={idx} value={thread.uuid}>{thread.title}</option>)}
                    <option value="new">Criar novo chat ...</option>
                </select>
            </div>
            <div id="chat-data">
                {messages.map((message, idx) => <ChatMessage key={idx} message={message} />)}
            </div>
            <div id="chat-message-bar">
                <textarea
                    id="message"
                    name="message"
                    className="form-control"
                    placeholder="Faça uma pergunta..."
                    type="text"
                    onChange={handleChange}
                    value={data.message}
                    rows={4}
                    cols={40}
                    disabled={loading}
                ></textarea>
                <Button
                    onClick={sendQuestion}
                    disabled={loading}
                >
                    Enviar
                </Button>
            </div>
        </div>
    );
}

export default Chat;
