const Question = ({ message }) => {
    return (
        <div style={{ backgroundColor: "beige", marginBottom: 10 }}>
            Você: {message.message}
        </div>
    );
};

const Answer = ({ message }) => {
    return (
        <div style={{ backgroundColor: "white", marginBottom: 10 }}>
            Dimo.ai: {message.message}
            {message.status === 'pending' ? '(processando)' : ''}
        </div>
    );
};

const ChatMessage = ({ message }) => {
    console.log(message);
    switch (message.type) {
        case 'question':
            return <Question message={message} />;
        case 'answer':
            return <Answer message={message} />;
        default:
            return (
                <div>
                    {JSON.stringify(message)}
                </div>
            );
    }
}

export default ChatMessage;
