import React from 'react';
import Chat from './Chat';

import './Main.css';

const Main = () => {
    return (
        <div id="container">
            <Chat />
        </div>
    );
}

export default Main;
