import React, { useState } from 'react';
// import axios from "axios";
// const env = process.env["ENV"] || "local";
// const config = require(`../../amplify/backend/function/dimoBL/opt/config/${env}.json`).elevenlabs;

const AudioStream = ({ text }) => {
    const [loading, setLoading] = useState(false);

    const handleGenerateElevenLabsAudio = async () => {
        // setLoading(true);
    
        // const baseUrl = config.baseUrl + "/text-to-speech";
        // const headers = {
        //   "Content-Type": "application/json",
        //   "xi-api-key": config.apiKey,
        // };
    
        // const requestBody = {
        //   text: text,
        //   model_id: config.model,
        //   voice_settings: {
        //     stability: 0,
        //     similarity_boost: 0,
        //     use_speaker_boost: 0
        //   },
        // };
    
        // try {
        //   const response = await axios.post(`${baseUrl}/${config.voiceId}/stream`, requestBody, {
        //     headers,
        //     responseType: "blob",
        //   });
    
        //   console.log("response", response);
        //   if (response.status === 200) {
        //     const audio = new Audio(URL.createObjectURL(response.data));
        //     console.log("audio", audio);
        //     audio.play();
        //   } else {
        //     console.log("Error: Unable to stream audio.");
        //   }
        // } catch (error) {
        //   console.log("Error: Unable to fetch audio.");
        // } finally {
        //   setLoading(false);
        // }
      };

    return <button disabled={loading} onClick={handleGenerateElevenLabsAudio}>ouvir</button>
}

export default AudioStream;