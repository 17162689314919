import ChatV2 from './components/ChatV2/ChatV2';
import Main from './components/Main';
import { Routes, Route, Outlet } from "react-router-dom";

function Layout() {
    return (
        <Outlet />
    );
}

function Home() {
    return (
        <div>
            dimo.ai
        </div>
    );
}

const App = () => {  
    return (
        <Routes>
            <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="test-interface" element={<Main />} />
            <Route path="teste-interface-v2" element={<ChatV2 />} />
            <Route path="*" element={<Home />} />
            </Route>
        </Routes>
    );
}

export default App;
