import AudioStream from "../AudioStream";

const Question = ({ message }) => {
    return (
        <div style={{ paddingBottom: 10, paddingTop: 10, borderBottom: "1px solid #4a4a4a" }}>
            <p>
                Você: {message.message}
            </p>
            {/* <AudioStream text={message.message} /> */}
        </div>
    );
};

const Answer = ({ message }) => {
    return (
        <div style={{ paddingBottom: 10, paddingTop: 10, borderBottom: "1px solid #4a4a4a" }}>
            <p>
                Dimo.ai: {message.message}
                {message.status === 'pending' ? '(processando)' : ''}
            </p>
            {/* <AudioStream text={message.message} /> */}
        </div>
    );
};

const ChatMessage = ({ message }) => {
    // console.log(message);
    switch (message.type) {
        case 'question':
            return <Question message={message} />;
        case 'answer':
            return <Answer message={message} />;
        default:
            return (
                <div>
                    {JSON.stringify(message)}
                </div>
            );
    }
}

export default ChatMessage;
